<template>
  <div>
    <div class="container mx-auto mt-2">
      <!-- Viewer -->
      <div class="text-gray-600 viewer-content">
        <div class="w-full h-64 bg-scene-gray">
          <PrevizViewerContainer
            id="rawtest"
            mode="sequence"
          />
        </div>
      </div>
      <div class="mt-2 grid-container view">
        <!-- End Viewer -->

        <div
          v-if="ready"
          class="w-full p-4 my-4 bg-gray-300"
        >
          <h4 class="mb-2 text-sm font-bold uppercase">
            Player
          </h4>

          <div class="p-4 my-2 bg-gray-700 rounded">
            <div>
              <a
                href="#"
                class="mx-1 text-blue-600 btn primary"
                @click.prevent="jumpToStart"
              >
                <fa-icon icon="backward-step" />
              </a>
              <a
                href="#"
                class="mx-1 text-blue-600 btn primary"
                @click.prevent="togglePlayState"
              >
                <fa-icon
                  v-if="playerState === 'paused'"
                  icon="play"
                />
                <fa-icon
                  v-else
                  icon="pause"
                />
              </a>

              <a
                href="#"
                class="mx-1 text-blue-600 btn primary"
                :class="{'disabled' : !canDecreasePlaybackRate }"
                @click.prevent="decreasePlaybackRate"
              >
                <fa-icon icon="backward" />
              </a>
              <a
                href="#"
                class="mx-1 text-blue-600 btn primary"
                :class="{'disabled' : !canIncreasePlaybackRate }"
                @click.prevent="increasePlaybackRate"
              >
                <fa-icon icon="forward" />
              </a>
              <span class="font-mono text-white">{{ playbackRate }}x</span>
            </div>
          </div>

          <div
            class="p-4 my-2 bg-gray-700 rounded"
          >
            <div class="px-2 py-1 font-mono text-xs text-gray-200 cursor-default">
              {{ currentTimecode }} / {{ durationFormatted }}
            </div>
          </div>

          <div class="p-4 my-2 bg-gray-700 rounded">
            <div
              class="relative w-full h-2 bg-gray-300 cursor-pointer"
              @click.prevent="jumpToProgressBar"
            >
              <div class="absolute z-50 w-full h-full opacity-0" />  <!-- this prevents the click events hitting the variable length progress bar, so our jump to calcs are consistent -->
              <div class="absolute w-full h-full bg-gray-600">
                <div
                  class="h-full bg-red-600"
                  :style="progressBarStyle"
                />
              </div>
            </div>
          </div>

          <div class="p-4 my-2 bg-gray-700 rounded">
            <h4 class="mb-2 text-sm font-bold text-gray-200 uppercase">
              Buffer State
            </h4>

            <div
              class="relative w-full h-2 bg-gray-300 cursor-pointer"
              @click.prevent="jumpToProgressBar"
            >
              <div class="absolute w-full h-full bg-gray-800">
                <template v-for="buffer in buffered">
                  <div
                    class="absolute h-full bg-blue-600"
                    :style="bufferBarStyle(buffer)"
                  />
                </template>
              </div>
            </div>

            <template v-for="buffer in buffered">
              <div class="font-mono text-xs text-gray-100">
                {{ buffer.start_at }} > {{ buffer.end_at }}
              </div>
            </template>
          </div>

          <div class="p-4 my-2 bg-gray-700 rounded">
            <h4 class="mb-2 text-sm font-bold text-gray-200 uppercase">
              Jump Commands
            </h4>

            <h4 class="mb-1 text-sm text-gray-300">
              By Time Code
            </h4>
            <div class="mb-2 -ml-1">
              <a
                href="#"
                class="mx-1 btn btn-xs"
                @click.prevent="jumpToTime(10)"
              >
                00:10
              </a>

              <a
                href="#"
                class="mx-1 btn btn-xs"
                @click.prevent="jumpToTime(32)"
              >
                00:32
              </a>

              <a
                href="#"
                class="mx-1 btn btn-xs"
                @click.prevent="jumpToTime(45)"
              >
                00:45
              </a>

              <a
                href="#"
                class="mx-1 btn btn-xs"
                @click.prevent="jumpToTime(60)"
              >
                01:00
              </a>

              <a
                href="#"
                class="mx-1 btn btn-xs"
                @click.prevent="jumpToTime(70)"
              >
                01:10
              </a>
            </div>
          </div>
        </div>

        <div
          v-if="ready"
          class="w-full p-4 my-4 overflow-scroll bg-gray-300"
        >
          <h4 class="mb-2 text-sm font-bold uppercase">
            Debug
          </h4>

          <p>
            State : <span class="font-mono text-green-500">{{ playerState }}</span> <span
              v-if="blocked > 0"
              class="px-2 py-1 mx-1 font-mono text-sm text-white bg-red-500"
            >BLOCKED - {{ blocked }}</span>
          </p>

          <p>
            <a
              href="#"
              class="text-blue-600 underline"
              @click.prevent="jumpToStart"
            >Back to Start</a>
          </p>
          <p>Progress % <span class="block font-mono text-sm text-gray-700">{{ progressPc }}</span></p>
          <p>Timecode <span class="block mr-1 font-mono text-sm text-gray-700 ">{{ currentSeconds }}s of {{ duration }}s</span></p>
          <p>Raw Time : <span class="mr-1 font-mono text-sm text-gray-700">{{ currentTime }}</span></p>
        </div>

        <div
          v-if="ready"
          class="w-full p-4 my-4 bg-gray-300"
        >
          <h4 class="mb-2 text-sm font-bold uppercase">
            Sequence - Active
          </h4>

          <div
            v-for="item in activeLayers"
            :key="item.id"
            class="inline-block px-2 py-1 m-1 overflow-hidden font-mono text-xs bg-white border border-gray-400 rounded-sm"
          >
            <div class="block mb-2">
              {{ item.id }} : {{ item.target }}
            </div>
            <div class="block">
              <span class="mr-1 font-mono font-bold text-blue-600">{{ item.resource_id }}</span>
              <span class="font-mono">{{ item.start_at }} > {{ item.end_at }}</span>
            </div>
          </div>

          <div v-if="activeLayers.length < 1">
            No active layers
          </div>
        </div>

        <div
          v-if="ready"
          class="w-full p-4 my-4 overflow-hidden bg-gray-300"
        >
          <h4 class="mb-2 text-sm font-bold uppercase">
            Sequence - Resources
          </h4>
          <div
            v-for="(item,i) in resourceItems"
            :key="i"
            class="px-2 py-1 m-1 overflow-hidden text-sm bg-white border border-gray-400"
          >
            <div class="block font-mono font-bold text-blue-500">
              <fa-icon
                v-if="item.state === 'loaded'"
                icon="circle"
                size="sm"
                class="text-green-500"
              />
              <fa-icon
                v-else
                icon="circle"
                size="sm"
                class="text-orange-500"
              />
              {{ item.id }}
            </div>
            <div class="block font-mono">
              {{ item.type }}
            </div>
            <span class="block font-mono text-xs text-gray-800">{{ item.url }}</span>
            <span class="block font-mono text-xs text-gray-800">{{ item.bufferedstate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PrevizViewer from '@previz/viewer'
const PrevizViewerContainer = () => import('@components/viewer/container/PrevizViewer')

export default {
  name: 'RawViewer',
  components: {
    PrevizViewerContainer
  },

  props: {
    sequence: {
      type: Object,
      required: true
    },
    resources: {
      type: Array,
      required: true
    }
  },

  data () {
    return {

      viewer: null,
      timeline: null,
      seqResources: null
    }
  },

  computed: {

    buffered () {
      return this.timeline.buffered
    },

    ready () {
      return this.timeline !== null
    },

    playerState () {
      if (this.isPlaying) return 'playing'
      return 'paused'
    },

    blocked () {
      if (this.ready) {
        return this.timeline._blocked
      }
      return 0
    },

    isPlaying () {
      if (this.ready) {
        return this.timeline.isPlaying
      }
      return false
    },

    currentTime () {
      return this.timeline.currentTime
    },

    progressPc () {
      return this.timeline.progressPc
    },

    currentTimecode () {
      return this.timeline.currentTimecode
    },

    duration () {
      return this.timeline.duration
    },

    progressBarStyle () {
      return 'width: ' + this.progressPc + '%'
    },

    durationFormatted () {
      return this.timeline.durationFormatted
    },

    currentSeconds () {
      return this.timeline.currentSeconds
    },

    playbackRate () {
      return Number.parseFloat(this.timeline.playbackRate)
    },

    canIncreasePlaybackRate () {
      return (this.playbackRate < 10)
    },

    canDecreasePlaybackRate () {
      return (this.playbackRate > 0.2)
    },

    layers () {
      return this.timeline.layers
    },

    activeLayers () {
      return this.timeline.active
    },

    resourceItems () {
      return this.seqResources.items
    },

    videoResources () {
      return this.resources.filter(function (row) {
        return row.type === 'video'
      })
    }

  },

  mounted () {
    this.initViewer()
  },

  methods: {

    bufferBarStyle (buffer) {
      return 'width: ' + buffer.duration_pc + '%; left: ' + buffer.start_at_pc + '%'
    },

    togglePlayState () {
      this.timeline.togglePlay()
    },

    jumpToStart () {
      this.timeline.jumpToStart()
    },

    increasePlaybackRate () {
      this.timeline.increasePlaybackRate()
    },

    decreasePlaybackRate () {
      this.timeline.decreasePlaybackRate()
    },

    jumpToTime (value) {
      this.timeline.jumpToTime(value)
    },

    jumpToProgressBar (event) {
      let offset = event.offsetX
      let width = event.target.clientWidth

      let pc = offset / width

      this.jumpToTime(this.duration * pc)
    },

    initViewer () {
      // let viewer = new PrevizSequenceViewer(
      //   this.sequence,
      //   this.resources
      // )

      // window.previzviewer = viewer

      // this.timeline = viewer.core.sequence.timeline

      // this.seqResources = viewer.core.sequence.resources
      // this.viewer = viewer
    }
  }
}
</script>
